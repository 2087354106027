<template>
    <el-dialog
        :title="mode == 'add' ? '新增安装包' : '编辑安装包'"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" class="demo-ruleForm">
            <el-form-item label="系统" prop="system">
                <el-select  v-model="form.system" placeholder="请选择系统">
                    <el-option
                    v-for="item in options"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="前端信息">
                <el-input type="textarea" :rows="2" v-model="form.frontInfo" placeholder="请输入前端信息"></el-input>
            </el-form-item>
            <el-form-item label="后端信息">
                <el-input type="textarea" :rows="2" v-model="form.backInfo" placeholder="请输入后端信息"></el-input>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="medium" @click="save">保存</el-button>
            <el-button size="medium" @click="closeDialog">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props:{
        versionId: {
            type: String
        }
    },
    data() {
        return {
            dialogVisible: false,
            mode: 'add',
            form: {
                frontInfo: '',
                backInfo: '',
                system: 0
            },
            rules: {
                system: [
                    { required: true, message: '请选择系统', trigger: 'change' }
                ]
            },
            options: [{
                value: 0,
                label: 'Web'
            },{
                value: 1,
                label: 'Windows'
            },{
                value: 2,
                label: 'Mac'
            },{
                value: 3,
                label: 'Linux'
            },{
                value: 4,
                label: 'Android'
            },{
                value: 5,
                label: 'IOS'
            }]
        };
    },
    methods: {
        onLoad(type, row) {
            this.dialogVisible = true;
            this.mode = type;
            if(type == 'add') {
                this.form = {
                    frontInfo: '',
                    backInfo: '',
                    system: 0,
                    versionId: this.versionId
                }
            } else {
               this.getDetail(row.id);
            }
            this.$refs['form'] ? this.$refs['form'].resetFields() : '';
        },
        // 获取详情
        getDetail(id) {
            this.$store.dispatch('manage/public', {
                query: id,
                requestInterfaceName: 'getInstallPackageDetail'
            }).then(res=>{
                if(res.code == 0) {
                    this.form = {
                        frontInfo: res.data.frontInfo,
                        backInfo: res.data.backInfo,
                        system: res.data.system,
                        id: res.data.id
                    }
                }
            })
        },
        // 保存
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.$store.dispatch('manage/public', {
                        query: this.form,
                        requestInterfaceName: this.mode == 'add' ? 'addInstallPackage' : 'editInstallPackage',
                    }).then(res=>{
                        if(res.code == 0) {
                            this.$message.success(this.mode == 'add' ? '添加成功' : '修改成功');
                            this.$parent.onList();
                            this.$parent.renovateVersionList();
                        } else {
                            this.$message.error(res.message);
                        }
                        this.closeDialog();
                    })
                }
            });
        },
        closeDialog() {
            this.dialogVisible = false;
            this.$refs['form'].resetFields();
        }
    }
};
</script>
<style lang="less" scoped>
.demo-ruleForm {
    /deep/ .el-upload {
        width: 120px;
        height: 120px;
        line-height: 124px;
    }
}
</style>