<template>
    <el-dialog
        :title="mode == 'add' ? '新增版本' : '编辑版本'"
        :visible.sync="dialogVisible"
        :close-on-click-modal="false">
        <el-form :model="form" :rules="rules" ref="form" label-width="80px" class="demo-ruleForm">
            <el-form-item label="版本名称" prop="name">
                <el-input v-model="form.name" placeholder="请输入版本名称"></el-input>
            </el-form-item>
            <el-form-item label="发布说明">
                <el-input type="textarea" :rows="2" v-model="form.releaseNotes" placeholder="请输入内容"></el-input>
            </el-form-item>
            <el-form-item label="发布日期" prop="releaseDate">
                <el-date-picker
                v-model="form.releaseDate"
                type="date"
                placeholder="选择发布日期">
                </el-date-picker>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" size="medium" @click="save">保存</el-button>
            <el-button size="medium" @click="closeDialog">取 消</el-button>
        </span>
    </el-dialog>
</template>
<script>
export default {
    props:{
        vkey: {
            type: String
        },
        toolId: {
            type: String
        }
    },
    data() {
        return {
            dialogVisible: false,
            mode: 'add',
            form: {
                name: '',
                releaseNotes: '',
                releaseDate: '',
                toolId: ''
            },
            rules: {
                name: [
                    { required: true, message: '请输入版本名称', trigger: 'blur' }
                ],
                releaseDate: [
                    { required: true, message: '请选择发布日期', trigger: 'change' }
                ]
            }
        };
    },
    methods: {
        show(type, row) {
            this.dialogVisible = true;
            this.mode = type;
            if(type == 'add') {
                this.form = {
                    name: '',
                    releaseNotes: '',
                    releaseDate: '',
                    toolId: this.toolId
                }
            } else {
                this.getVersonDetail(row.id);
            }
            this.$refs['form'] ? this.$refs['form'].resetFields() : '';
        },
        // 获取详情
        getVersonDetail(id) {
            this.$store.dispatch('manage/public', {
                query: id,
                requestInterfaceName: 'getVersionDetail'
            }).then(res=>{
                if(res.code == 0) {
                    this.form = {
                        id: res.data.id,
                        name: res.data.name,
                        releaseNotes: res.data.releaseNotes,
                        releaseDate: res.data.releaseDate
                    }
                }
            })
        },
        // 保存
        save() {
            this.$refs['form'].validate((valid) => {
                if (valid) {
                    this.form.releaseDate = this.timeFormatEvent(this.form.releaseDate, false);
                    this.$store.dispatch(`manage/${this.mode == 'add' ? 'add' : 'edit'}`, {
                        vkey: this.vkey,
                        query: this.form,
                        requestInterfaceName: this.mode == 'add' ? 'addVersion' : 'editVersion',
                        listInterfaceName: 'getVersionList'
                    }).then(res=>{
                        if(res.code == 0) {
                            this.$message.success(this.mode == 'add' ? '添加成功' : '修改成功');
                        } else {
                            this.$message.error(res.message);
                        }
                        this.closeDialog();
                    })
                }
            });
        },
        timeFormatEvent(timeVal, flag) {
            if (!timeVal) {
                return ''
            }
            let a = new Date(timeVal).getTime();
            const date = new Date(a);
            const Y = date.getFullYear() + '-';
            const M = (date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1) + '-';
            const D = (date.getDate() < 10 ? '0' + date.getDate() : date.getDate()) + ' ';
            const h = (date.getHours() < 10 ? '0' + date.getHours() : date.getHours()) + ':';
            const m = (date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes()) + ':';
            const s = (date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds()); // 秒

            // true 展示 年月日时分秒
            if (flag) {
                return Y + M + D + h + m + s;
            } else {
                return Y + M + D;
            }
        },
        closeDialog() {
            this.dialogVisible = false;
            this.$refs['form'].resetFields();
        }
    }
};
</script>
<style lang="less" scoped>
.demo-ruleForm {
    /deep/ .el-upload {
        width: 120px;
        height: 120px;
        line-height: 124px;
    }
}
</style>