<template>
    <div>
        <el-dialog title="安装包管理" :visible.sync="dialogTableVisible">
            <el-button type="primary" icon="el-icon-plus" size="medium" style="margin: 0 0 20px 0;" @click="handleInstallPackageEvent('add')">添加</el-button>
            <template>
                <TableCommon isMargin="20px" :tableColumn="tableColumn" :tableData="tableData">
                    <el-table-column
                    label="操作"
                    align="center"
                    width="130">
                        <template slot-scope="scope">
                            <el-button type="text" icon="el-icon-edit" size="medium" @click="handleInstallPackageEvent('edit', scope.row)">编辑</el-button>
                            <el-button type="text" icon="el-icon-delete" size="medium" @click="handleInstallPackageEvent('remove', scope.row)">删除</el-button>
                        </template>
                    </el-table-column>
                </TableCommon>
            </template>
        </el-dialog>

        <addPackage ref="addPackageDialog" :versionId="rowInfo.id"></addPackage>
    </div>
</template>
<script>
import TableCommon from '../../../components/TableCommon.vue';
import addPackage from './components/addPackage.vue';
export default {
    data() {
        return {
            dialogTableVisible: false,
            formInline: {},
            tableColumn: [
                { label: '序号', type: 'index', align: 'center', width: '60' },
                { label: '系统', prop: 'system', align: 'center' }
            ],
            tableData: [],
            rowInfo: {}
        };
    },
    components: {
        TableCommon,
        addPackage
    },
    methods: {
        onInit(row) {
            this.dialogTableVisible = true;
            this.rowInfo = row;
            this.onList();
        },
        onList() {
            this.formInline.versionId = this.rowInfo.id;
            this.$store.dispatch('manage/public', {
                query: this.formInline,
                requestInterfaceName: 'getInstallPackageList'
            }).then(res=>{
                if(res.code == 0) {
                    this.tableData = res.data;
                    this.tableData.map(item=>{
                        item.system = this.mapData(item.system);
                    })
                }
            })
        },
        mapData(system) {
            switch(system) {
                case 0:
                    return 'Web'
                case 1:
                    return 'Windows'
                case 2:
                    return 'Mac'
                case 3:
                    return 'Linux'
                case 4:
                    return 'Android'
                case 5:
                    return 'IOS'
            }
        },
        // 按钮操作
        handleInstallPackageEvent(type, row) {
            switch(type) {
                case 'add':
                    this.$nextTick(()=>{
                        this.$refs.addPackageDialog.onLoad(type)
                    })
                break;

                case 'edit':
                    this.$nextTick(()=>{
                        this.$refs.addPackageDialog.onLoad(type, row)
                    })
                break;

                case 'remove':
                    this.$confirm('确定是否要删除当前选择的数据?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$store.dispatch('manage/public', {
                            query: row.id,
                            requestInterfaceName: 'removeInstallPackage',
                        }).then(res=>{
                            if(res.code == 0) {
                                this.$message.success('删除成功');
                                this.onList();
                            } else {
                                this.$message.error(res.message);
                            }
                        })
                    }).catch(() => {});
                break;
            }
        },
        // 刷新版本列表
        renovateVersionList() {
            this.$parent.onLoadList();
        }
    }
};
</script>